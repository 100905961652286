import { Controller } from "@hotwired/stimulus"

const Politeness = {
  POLITE: 'polite',
  ASSERTIVE: 'assertive'
};

export default class extends Controller {
  static targets = [ "assertiveZone", "politeZone" ]

  // TODO: Add "notification target" that adds itself to appropriate zone when connected

  notify({ params: { message, politeness }}) {
    const zoneTarget = this.#getTargetFor(politeness)
    const messageElement = this.#createElementWith(message)

    zoneTarget.append(messageElement)
  }

  // Private
  #createElementWith(message) {
    const element = document.createElement("p")
    element.innerText = message

    return element
  }

  #getTargetFor(mode = Politeness.POLITE) {
    if (mode === Politeness.ASSERTIVE) {
      return this.assertiveZoneTarget
    }

    return this.politeZoneTarget
  }
}
